const booksDataConfig = {
    halloween: "./data/halloween-books.json",
    default: "./data/books.json"
}

export function getDataPathFromId(id) {
    if (booksDataConfig[id]) {
        return booksDataConfig[id];
    }

    return booksDataConfig["default"];
}

// export default booksDataConfig;