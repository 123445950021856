<template>
  <div class="pampers__top">
    <div class="pampers__header">
      <h1>Storybook Library</h1>
      <div class="pampers__header-brand">
        <img
          src="@/assets/img/logo-pampers.png"
          alt="Pampers Logo"
          class="logo"
        />
        <span>What's New</span>
      </div>
    </div>
    <div class="pampers__featured">
      <div v-for="featuredItem in featured" :key="featuredItem.id">
        <div class="pampers__featured-title">{{ featuredItem.title }}</div>
        <div class="pampers__shelf">
          <a
            :href="featuredItem.lensUrl"
            @click.prevent="handleBookClick(featuredItem)"
            target="_blank"
            class="pampers__shelf-book"
          >
            <img
              :src="getImgUrl(featuredItem.imagePath)"
              :alt="featuredItem.title"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { getData } from "../store/index";

export default {
  name: "Header",
  data() {
    return {
      featured: [],
    };
  },
  methods: {
    getImgUrl(cover) {
      return "books/" + cover;
    },
    getBookData() {
      const response = getData();
      this.featured = response.data.featured;
      // axios
      //   .get(jsonFile)
      //   .then((response) => (this.featured = response.data.featured));
    },
    handleBookClick(book) {
      // console.log(book);
      // console.log(book.lensUrl);
      // console.log(window.ga);
      window.ga("send", "event", "book-outbound", "click", book.lensUrl, {
        transport: "beacon",
        hitCallback: function () {
          // document.location = book.lensUrl;
          window.open(book.lensUrl, "_blank");
        },
      });
    },
  },
  created() {
    this.getBookData();
  },
};
</script>

<style scoped>
.pampers__top {
  background: url(../assets/img/bg-clouds.png) top -40px center no-repeat;
  background-size: 1250px auto;
  min-height: 400px;
  color: var(--white);
  padding-top: 100px;
  text-align: center;
}

.pampers__top h1 {
  font-weight: 400;
  font-size: 24px;
}

.pampers__header-brand {
  max-width: 600px;
  width: 90%;
  margin: 10px auto 0;
  font-family: "Pampers Text";
}

.pampers__header-brand .logo {
  width: 40%;
  max-width: 100px;
  display: inline-block;
}

.pampers__header-brand span {
  display: inline-block;
  font-size: 30px;
  margin: 0 0 0 20px;
  position: relative;
  top: -5px;
}

.pampers__featured-title {
  background-color: var(--yellow);
  color: var(--green);
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  min-width: 200px;
  padding: 0 30px;
  margin: 10px auto 20px;
  position: relative;
}

.pampers__featured-title:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -35px;
  width: 30px;
  height: 70px;
  background: url(../assets/img/doodle-button_left.png) left center no-repeat;
  background-size: 100% auto;
}

.pampers__featured-title:after {
  content: "";
  position: absolute;
  top: -10px;
  right: -35px;
  width: 30px;
  height: 70px;
  background: url(../assets/img/doodle-button_right.png) left center no-repeat;
  background-size: 100% auto;
}

@media only screen and (min-width: 600px) {
  .pampers__top h1 {
    font-size: 32px;
  }

  .pampers__header-brand {
    margin-top: 20px;
  }

  .pampers__header-brand .logo {
    max-width: 190px;
  }

  .pampers__header-brand span {
    font-size: 60px;
    margin: 0 0 0 40px;
    top: -10px;
  }

  .pampers__featured-title {
    margin: 10px auto 40px;
    border-radius: 35px;
    padding: 0 50px;
    font-size: 32px;
    min-width: 300px;
    height: 70px;
    line-height: 70px;
  }

  .pampers__featured-title:before {
    top: -10px;
    left: -75px;
    width: 70px;
    height: 100px;
  }

  .pampers__featured-title:after {
    top: -10px;
    right: -75px;
    width: 70px;
    height: 100px;
  }
}

@media only screen and (min-width: 1250px) {
  .pampers__top {
    background-size: 100% auto;
    background-position: top center;
    padding-top: 200px;
  }
}
</style>