import VueRouter from "vue-router"
import Home from "../components/Home"
import axios from 'axios'
import { getDataPathFromId } from "../helpers/booksDataConfig"
import { setData } from "../store/index"

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        props: true,
        beforeEnter: (to, from, next) => {
            const jsonId = to.query.listId;
            const path = getDataPathFromId(jsonId);
            axios.get(path).then((response) => {
                // console.log(response);
                setData(response);
                next();
            })

        }
    },
    // {

    //     path: "*",
    //     name: "home"
    // }
]

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes
})


export default router;