var store = null;


export function setData(data) {
    store = data;
}

export function getData() {
    if (store) {
        return store;
    } else {
        return null;
    }
}