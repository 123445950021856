<template>
  <div id="home">
    <Header />
    <BookShelf />
  </div>
</template>

<script>
import Header from "./Header.vue";
import BookShelf from "./BookShelf.vue";

export default {
  name: "Home",
  components: {
    Header,
    BookShelf,
  },
  //   created() {
  //     console.log("created home");
  //     const jsonId = this.$route.query.id;

  //     if (jsonId) {
  //       console.log(jsonId);
  //     } else {
  //       console.log("no id");
  //     }
  //     // console.log(this.$route.query);
  //   },
};
</script>

<style>
#home {
  font-family: "Montserrat", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>