import Vue from 'vue'
import App from './App.vue'
import VuePaginate from 'vue-paginate'
import VueRouter from 'vue-router'
import router from "./router/index"

Vue.use(VuePaginate);

Vue.use(VueRouter);

Vue.config.productionTip = false

// Importing the global css file
import "@/assets/css/global.css"

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

