<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Home from "./components/Home";

export default {
  name: "App",
  // components: {
  //   Home,
  // },
};
</script>

<style>
#app {
  font-family: "Montserrat", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>