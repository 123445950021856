<template>
  <div class="pampers__library">
    <div class="pampers__library-header">
      Browse <br />
      <span class="pampers__library-title">Pampers Library</span>
      <paginate-links
        for="shelves"
        :simple="{
          prev: 'Back',
          next: 'Next',
        }"
      ></paginate-links>
    </div>
    <div class="pampers__library-grid">
      <paginate name="shelves" :list="makeRows" :per="rowsPerPage">
        <li v-for="(row, i) in paginated('shelves')" class="row" :key="i">
          <div v-for="(bookItem, y) in row" class="col" :key="y">
            <div class="pampers__library-item">
              <!-- <a :href="bookItem.lensUrl" target="_blank" class="pampers__shelf-book"> -->
              <a
                :href="bookItem.lensUrl"
                @click.prevent="handleBookClick(bookItem)"
                target="_blank"
                class="pampers__shelf-book"
              >
                <img
                  :src="getImgUrl(bookItem.imagePath)"
                  :alt="bookItem.title"
                />
              </a>
            </div>
          </div>
        </li>
      </paginate>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { getData } from "../store/index";
export default {
  name: "BookShelf",
  data() {
    return {
      itemsPerRow: 3,
      itemsPerRowDesktop: 3,
      itemsPerRowTablet: 2,
      itemsPerRowMobile: 1,
      rowsPerPage: 1,
      books: [],
      paginate: ["shelves"],
    };
  },
  created() {
    // console.log(booksDataConfig);
    window.addEventListener("load", this.onResize);
    window.addEventListener("resize", this.onResize);
    this.getBookData();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getImgUrl(cover) {
      return "books/" + cover;
    },
    getBookData() {
      let response = getData();
      this.books = response.data.books;
      this.itemsPerRow = response.data.itemsPerRow;
      this.itemsPerRowDesktop = response.data.itemsPerRow;
      this.itemsPerRowTablet = response.data.itemsPerRowTablet;
      this.itemsPerRowMobile = response.data.itemsPerRowMobile;
      this.rowsPerPage = response.data.rowsPerPage;

      // axios
      //   .get(jsonFile)
      //   .then(
      //     (response) => (
      //       (this.books = response.data.books),
      //       (this.itemsPerRow = response.data.itemsPerRow),
      //       (this.itemsPerRowDesktop = response.data.itemsPerRow),
      //       (this.itemsPerRowTablet = response.data.itemsPerRowTablet),
      //       (this.itemsPerRowMobile = response.data.itemsPerRowMobile),
      //       (this.rowsPerPage = response.data.rowsPerPage)
      //     )
      //   );
    },
    onResize() {
      const width = window.innerWidth;
      if (width < 768) this.itemsPerRow = this.itemsPerRowMobile;
      else if (width < 1080) this.itemsPerRow = this.itemsPerRowTablet;
      else this.itemsPerRow = this.itemsPerRowDesktop;
    },
    handleBookClick(book) {
      // console.log(book);
      // console.log(book.lensUrl);
      // console.log(window.ga);
      window.ga("send", "event", "book-outbound", "click", book.lensUrl, {
        transport: "beacon",
        hitCallback: function () {
          // document.location = book.lensUrl;
          window.open(book.lensUrl, "_blank");
        },
      });
    },
  },
  computed: {
    makeRows() {
      let row = [];
      let i,
        l,
        chunkSize = this.itemsPerRow;

      for (i = 0, l = this.books.length; i < l; i += chunkSize) {
        row.push(this.books.slice(i, i + chunkSize));
      }
      return row;
    },
  },
};
</script>

<style>
.pampers__library {
  background: #f5f5f3 url(../assets/img/bg-floor.jpg) bottom center no-repeat;
  background-size: 1250px auto;
  color: var(--green);
  padding: 20px 0 140px;
  overflow-x: hidden;
}

.pampers__library-header {
  font-family: "Pampers Text";
  position: relative;
  font-size: 32px;
  line-height: 32px;
  margin: 35px auto 0;
  display: inline-block;
}

.pampers__library-header:before {
  content: "";
  position: absolute;
  top: -35px;
  left: -30px;
  width: 50px;
  height: 70px;
  background: url(../assets/img/doodle-clouds.png) left center no-repeat;
  background-size: 100% auto;
}

.pampers__library-header:after {
  content: "";
  position: absolute;
  top: -45px;
  right: -30px;
  width: 32px;
  height: 80px;
  background: url(../assets/img/doodle-bear.png) left center no-repeat;
  background-size: 100% auto;
}

.pampers__library-header .paginate-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pampers__library-header .paginate-links li {
  position: absolute;
  top: 42px;
  left: -55px;
  display: block;
  width: 40px;
  height: 40px;
  background: #f15e3d url(../assets/img/icon-arrow_left.svg) left 14px center
    no-repeat;
  background-size: 20% auto;
  border-radius: 50%;
  transition: opacity 0.2s ease;
  opacity: 0.85;
}

.pampers__library-header .paginate-links li.disabled {
  display: none;
}

.pampers__library-header .paginate-links li:hover {
  opacity: 1;
}

.pampers__library-header .paginate-links li a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -999px;
  overflow: hidden;
  cursor: pointer;
}

.pampers__library-header .paginate-links li.next {
  left: auto;
  right: -55px;
  background: #f15e3d url(../assets/img/icon-arrow_right.svg) right 14px center
    no-repeat;
  background-size: 20% auto;
}

.pampers__library-title {
  font-family: "Montserrat", Arial, sans-serif;
  background-color: var(--green);
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  min-width: 200px;
  padding: 0 30px;
  margin: 10px auto 20px;
  position: relative;
}

.pampers__library-grid {
  width: 90%;
  margin: 60px auto 160px;
  max-width: 700px;
}

.pampers__library-grid ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pampers__library-grid .row {
  display: flex;
  position: relative;
  text-align: center;
  justify-content: space-around;
  margin-bottom: 80px;
  align-content: flex-end;
  padding: 0 5%;
}

.pampers__library-grid .row:after {
  content: "";
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  width: 100%;
  padding-bottom: 20%;
  background: url(../assets/img/shelf.png) bottom center no-repeat;
  background-size: 100% auto;
}

.pampers__library-grid .col {
  padding: 0 20px;
  display: flex;
  align-items: flex-end;
}

@media only screen and (min-width: 720px) {
  .pampers__library {
    padding-bottom: 200px;
  }

  .pampers__library-header {
    font-size: 50px;
    line-height: 50px;
    margin-top: 40px;
  }

  .pampers__library-title {
    margin: 0 auto 40px;
    border-radius: 35px;
    font-size: 32px;
    min-width: 300px;
    height: 70px;
    line-height: 70px;
    padding: 0 50px;
  }

  .pampers__library-header:before {
    top: 30px;
    left: -80px;
    width: 60px;
    height: 70px;
    background: url(../assets/img/doodle-clouds.png) left center no-repeat;
    background-size: 100% auto;
  }

  .pampers__library-header:after {
    top: 5px;
    right: -70px;
    width: 50px;
    height: 120px;
    background: url(../assets/img/doodle-bear.png) left center no-repeat;
    background-size: 100% auto;
  }

  .pampers__library-header .paginate-links li {
    width: 70px;
    height: 70px;
    top: 52px;
    left: -160px;
    background: #f15e3d url(../assets/img/icon-arrow_left.svg) left 25px center
      no-repeat;
    background-size: 20% auto;
  }

  .pampers__library-header .paginate-links li.next {
    left: auto;
    right: -160px;
    background: #f15e3d url(../assets/img/icon-arrow_right.svg) right 25px
      center no-repeat;
    background-size: 20% auto;
  }

  .pampers__library-grid .row {
    margin-bottom: 100px;
  }

  .pampers__library-grid .row:after {
    top: calc(100% - 20px);
  }
}

@media only screen and (min-width: 900px) {
  .pampers__library-header .paginate-links li {
    left: -240px;
  }

  .pampers__library-header .paginate-links li.next {
    left: auto;
    right: -240px;
  }

  .pampers__library-header:before {
    top: 20px;
    left: -135px;
    width: 100px;
    height: 100px;
  }

  .pampers__library-header:after {
    top: -30px;
    right: -115px;
    width: 70px;
    height: 200px;
  }
}

@media only screen and (min-width: 1250px) {
  .pampers__library-grid {
    margin-bottom: 0px;
  }

  .pampers__library {
    background-size: 100% auto;
    padding: 40px 0;
    padding-bottom: 250px;
  }
}

@media only screen and (min-width: 1600px) {
  .pampers__library-grid {
    margin-bottom: 200px;
  }

  .pampers__library {
    background-size: 100% auto;
    padding: 40px 0;
    padding-bottom: 400px;
  }
}
</style>